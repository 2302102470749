
import SimpleCard from "@/components/CardView/SimpleCard.vue";
import lottieGateway from "@/assets/animations/lottie-gateway.json";
import lottieExpose from "@/assets/animations/lottie-expose.json";
import lottieResource from "@/assets/animations/lottie-resource.json";
import lottieConnectivity from "@/assets/animations/lottie-connectivity.json";

export default {
  name: "LandingWhatWeDo",
  components: { SimpleCard },
  data() {
    return {
      itemList: [
        {
          title: this.$t("landing.section_what_we_do.gateway"),
          text: this.$t("landing.section_what_we_do.gateway_text"),
          lottie: lottieGateway,
        },
        {
          title: this.$t("landing.section_what_we_do.connectivity"),
          text: this.$t("landing.section_what_we_do.connectivity_text"),
          lottie: lottieConnectivity,
        },
        {
          title: this.$t("landing.section_what_we_do.expose"),
          text: this.$t("landing.section_what_we_do.expose_text"),
          lottie: lottieExpose,
        },
        {
          title: this.$t("landing.section_what_we_do.resources"),
          text: this.$t("landing.section_what_we_do.resources_text"),
          lottie: lottieResource,
        },
      ],
    };
  },
};
