
export default {
  name: "SimpleCard",
  props: {
    label: String,
    description: String,
    datetime: String,
    thumbnail: { type: String, default: "/images/default-image.png" },
    thumbnailAspect: { type: String, default: "1" },
  },
  computed: {
    startDate() {
      let isKm = this.$i18n.locale == "km";
      let date = this.datetime || new Date();
      if (isKm) return this.$moment(date).locale("km").format("DD MMM YYYY");
      return this.$moment(date).format("MMMM DD, YYYY");
    },
  },
};
